import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js'

import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { fEesCollector } from './fees'

let network = 'https://api.mainnet-beta.solana.com'

export const setDevnet = () => {
  network = 'https://api.devnet.solana.com'
}
export const setMainnet = () => {
  network = 'https://api.mainnet-beta.solana.com'
}

export const getConnection = async () => {
  try {
    const resp = await window.solana.connect()
    let userkey = resp.publicKey.toString()
    return userkey
  } catch (err) {
    return 'connect'
  }
}

export const getDisconnection = () => {
  window.solana.disconnect()
}

export const filterRecipientAddrress = (destination) => {
  let receivers = destination.split('\n')
  let mint_address = []
  let receivers_array = []
  receivers.forEach((item) => {
    let filter = item.split(',')
    mint_address.push(filter[1])
    receivers_array.push(filter[0])
  })

  return {
    token_array: mint_address,
    destination_array: receivers_array,
  }
}

export const getAllSenderTokenAccount = async (_owner, _token_array) => {
  const connection = new Connection(network)
  const owner = new PublicKey(_owner)

  let sender_address = []
  try {
    for (const item of _token_array) {
      const mint_address = new PublicKey(item)
      let sender_token_address = await connection.getTokenAccountsByOwner(
        owner,
        {
          mint: mint_address,
        }
      )
      if (sender_token_address.value.length !== 0) {
        let sender_token_account =
          sender_token_address.value[0].pubkey.toString()
        sender_address.push(sender_token_account)
      } else {
        alert('Input the valid token you own')
        return []
      }
    }
  } catch {
    alert('You can only transfer NFT that you own')
    return
  }
  return sender_address
}

export const getAllReceiverAddress = async (
  _reciver_list,
  _token_list,
  _payer
) => {
  const connection = new Connection(network)
  let payer = new PublicKey(_payer)
  let counter = 0
  let receivers = []
  let transactions = new Transaction()

  try {
    for (const item of _reciver_list) {
      let receiver_pubkey = new PublicKey(item)
      let token_pubkey = new PublicKey(_token_list[counter])

      let associated_tokenAccount = await connection.getTokenAccountsByOwner(
        receiver_pubkey,
        { mint: token_pubkey }
      )

      if (associated_tokenAccount.value.length !== 0) {
        receivers.push(associated_tokenAccount.value[0].pubkey)
      } else {
        let associated_account = await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          token_pubkey,
          receiver_pubkey
        )
        receivers.push(associated_account)

        let token_instruction = Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          token_pubkey,
          associated_account,
          receiver_pubkey,
          payer
        )

        transactions.add(token_instruction)
      }

      counter++
    }
  } catch {
    return
  }

  return { receivers, transactions }
}

export const NFTtransfer = async (
  _sender_token_accounts,
  _receiver_token_accounts,
  _token_address_list,
  transaction,
  _payer,
  _paymentMethod
) => {
  try {
    const connection = new Connection(network)

    let counter = 0

    for (const receiver of _receiver_token_accounts) {
      let source = new PublicKey(_sender_token_accounts[counter])
      let mint_address = new PublicKey(_token_address_list[counter])
      let owner = new PublicKey(_payer)
      let multisigner = {
        publicKey: owner,
      }

      let trasnfer_instruction = Token.createTransferCheckedInstruction(
        TOKEN_PROGRAM_ID,
        source,
        mint_address,
        receiver,
        owner,
        [multisigner],
        1,
        0
      )

      transaction.add(trasnfer_instruction)

      counter++
    }

    transaction.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transaction.recentBlockhash = await blockhashObj.blockhash

    const newsignedTransaction = await window.solana.signTransaction(
      transaction
    )
    let sig = await connection.sendRawTransaction(
      newsignedTransaction.serialize()
    )

    await connection.confirmTransaction(sig)

    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export const converter = async (_amount, _paymentMethod) => {
  let one_sol_to_usd
  let one_sol_to_usd_response
  let one_sol_to_usd_value
  let one_usd_to_sol
  let usd_to_sol
  let usd_to_lamports
  if (_paymentMethod === 'usdt') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.tether.usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else if (_paymentMethod === 'usdc') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response['usd-coin'].usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.solana.usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * LAMPORTS_PER_SOL

    return usd_to_lamports
  }

  // _amount: usd
  //   1 sol 253
  //
}

export const feeCalculator = async (_recivers, _paymentMethod) => {
  const feeCollector = new PublicKey(fEesCollector)
  let amount
  if (_recivers.length >= 1 && _recivers.length <= 99) {
    amount = await converter(25, _paymentMethod)
  } else if (_recivers.length >= 100 && _recivers.length <= 249) {
    amount = await converter(50, _paymentMethod)
  } else if (_recivers.length >= 250 && _recivers.length <= 499) {
    amount = await converter(75, _paymentMethod)
  } else if (_recivers.length >= 500 && _recivers.length <= 999) {
    amount = await converter(100, _paymentMethod)
  } else {
    // alert('Contact our team')
    amount = await converter(750)
  }

  if (_paymentMethod === 'usdt') {
    let ins = await payWithUSDT(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )

    return ins
  }
  if (_paymentMethod === 'usdc') {
    let ins = await payWithUSDC(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )

    return ins
  } else {
    let feeInstruction = SystemProgram.transfer({
      fromPubkey: window.solana.publicKey,
      lamports: amount,
      toPubkey: feeCollector,
    })

    return feeInstruction
  }
}

export const payWithUSDT = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  let new_token_instruction

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      alert('You can only transfer token that you own')
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      new_token_instruction = token_instruction
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  return { new_token_instruction, token_transfer_instruction }
}

export const payWithUSDC = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  let new_token_instruction

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      alert('You can only transfer token that you own')
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      new_token_instruction = token_instruction
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  return { new_token_instruction, token_transfer_instruction }
}
