export type Subscriptions = {
  version: '0.3.0'
  name: 'subscriptions'
  instructions: [
    {
      name: 'createAdminInfo'
      accounts: [
        {
          name: 'admin'
          isMut: true
          isSigner: true
        },
        {
          name: 'adminInfo'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'updateAdminWallet'
      accounts: [
        {
          name: 'admin'
          isMut: true
          isSigner: true
        },
        {
          name: 'adminInfo'
          isMut: true
          isSigner: false
        }
      ]
      args: [
        {
          name: 'newAdmin'
          type: 'publicKey'
        }
      ]
    },
    {
      name: 'updateFeeRecipient'
      accounts: [
        {
          name: 'admin'
          isMut: true
          isSigner: true
        },
        {
          name: 'adminInfo'
          isMut: true
          isSigner: false
        }
      ]
      args: [
        {
          name: 'feeRecipient1'
          type: 'publicKey'
        },
        {
          name: 'feeRecipient2'
          type: 'publicKey'
        }
      ]
    },
    {
      name: 'createUsersCounter'
      accounts: [
        {
          name: 'admin'
          isMut: true
          isSigner: true
        },
        {
          name: 'usersCounter'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'createSubscription'
      accounts: [
        {
          name: 'user'
          isMut: true
          isSigner: true
        },
        {
          name: 'userInfo'
          isMut: true
          isSigner: false
        },
        {
          name: 'usersCounter'
          isMut: true
          isSigner: false
        },
        {
          name: 'usersTable'
          isMut: true
          isSigner: false
        },
        {
          name: 'adminInfo'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'from'
          isMut: true
          isSigner: false
        },
        {
          name: 'to'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'plan'
          type: 'u8'
        }
      ]
    },
    {
      name: 'renewSubscription'
      accounts: [
        {
          name: 'user'
          isMut: true
          isSigner: true
        },
        {
          name: 'userInfo'
          isMut: true
          isSigner: false
        },
        {
          name: 'adminInfo'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'from'
          isMut: true
          isSigner: false
        },
        {
          name: 'to'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'plan'
          type: 'u8'
        }
      ]
    }
  ]
  accounts: [
    {
      name: 'adminInfo'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bump'
            type: 'u8'
          },
          {
            name: 'admin'
            type: 'publicKey'
          },
          {
            name: 'feeRecipient1'
            type: 'publicKey'
          },
          {
            name: 'feeRecipient2'
            type: 'publicKey'
          }
        ]
      }
    },
    {
      name: 'usersCounter'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bump'
            type: 'u8'
          },
          {
            name: 'totalUsers'
            type: 'u32'
          }
        ]
      }
    },
    {
      name: 'usersTable'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bump'
            type: 'u8'
          },
          {
            name: 'pdaAccount'
            type: 'publicKey'
          }
        ]
      }
    },
    {
      name: 'userInfo'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'bump'
            type: 'u8'
          },
          {
            name: 'user'
            type: 'publicKey'
          },
          {
            name: 'startTime'
            type: 'i64'
          },
          {
            name: 'endTime'
            type: 'i64'
          }
        ]
      }
    }
  ]
  errors: [
    {
      code: 6000
      name: 'UnauthorizedAdmin'
      msg: 'Unauthorized admin'
    },
    {
      code: 6001
      name: 'InvalidFeeRecipient'
      msg: 'Invalid FeeRecipient'
    }
  ]
}

export const IDL: Subscriptions = {
  version: '0.3.0',
  name: 'subscriptions',
  instructions: [
    {
      name: 'createAdminInfo',
      accounts: [
        {
          name: 'admin',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'adminInfo',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'updateAdminWallet',
      accounts: [
        {
          name: 'admin',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'adminInfo',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'newAdmin',
          type: 'publicKey',
        },
      ],
    },
    {
      name: 'updateFeeRecipient',
      accounts: [
        {
          name: 'admin',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'adminInfo',
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'feeRecipient1',
          type: 'publicKey',
        },
        {
          name: 'feeRecipient2',
          type: 'publicKey',
        },
      ],
    },
    {
      name: 'createUsersCounter',
      accounts: [
        {
          name: 'admin',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'usersCounter',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'createSubscription',
      accounts: [
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'userInfo',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'usersCounter',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'usersTable',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'adminInfo',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'from',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'to',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'plan',
          type: 'u8',
        },
      ],
    },
    {
      name: 'renewSubscription',
      accounts: [
        {
          name: 'user',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'userInfo',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'adminInfo',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'from',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'to',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'plan',
          type: 'u8',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'adminInfo',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'admin',
            type: 'publicKey',
          },
          {
            name: 'feeRecipient1',
            type: 'publicKey',
          },
          {
            name: 'feeRecipient2',
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'usersCounter',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'totalUsers',
            type: 'u32',
          },
        ],
      },
    },
    {
      name: 'usersTable',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'pdaAccount',
            type: 'publicKey',
          },
        ],
      },
    },
    {
      name: 'userInfo',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'bump',
            type: 'u8',
          },
          {
            name: 'user',
            type: 'publicKey',
          },
          {
            name: 'startTime',
            type: 'i64',
          },
          {
            name: 'endTime',
            type: 'i64',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'UnauthorizedAdmin',
      msg: 'Unauthorized admin',
    },
    {
      code: 6001,
      name: 'InvalidFeeRecipient',
      msg: 'Invalid FeeRecipient',
    },
  ],
}
