import styled, { createGlobalStyle, css } from 'styled-components'
export const Globalstyle = createGlobalStyle`
@import url("https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200&display=swap");
* {
    margin: 0%;
}
`
export const Navbar = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  @media (max-width: 700px) {
    width: 100%;
    display: flex;
  }
  background-color: ${(props) => {
    if (
      props.currNetwork === 'Solana' ||
      props.currNetwork === 'SolanaDev' ||
      props.currNetwork === 'SolanaDev'
    ) {
      return props.mode ? '#000' : 'white'
    } else {
      return props.mode ? props.theme.colors.blue : 'white'
    }
  }};
  .dropdown {
    margin-right: 20px;
    cursor: pointer;
  }
  .dropbtn2 {
    margin-left: -80px;
  }
  .userbox {
    cursor: pointer;
    font-weight: 600;
    margin: 20px;
    font-size: 12px;
    padding: 10px;
    border-radius: 20px;
    min-width: 60px;
    text-align: center;
    color: black;
    background-color: ${(props) => props.theme.colors.gold};
  }
  .logo {
    margin-left: 30px;
    font-weight: 800;
    height: 30px;
    @media (max-width: 700px) {
      margin-left: 7px;
      font-weight: 400;
      height: 16px;
    }
    @media (max-width: 300px) {
      height: 18px;
    }
    /* gold blue */
    color: ${(props) => {
      return props.mode ? props.theme.colors.white : props.theme.colors.blue
    }};
  }
`
export const Buttoncontainer = styled.div<any>`
  cursor: pointer;
  width: 10vw;
  margin-left: 10vh;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${(props) => {
    return props.mode ? props.theme.colors.gold : props.theme.colors.blue
  }};
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 3vh;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props) => {
    return props.mode ? props.theme.colors.gold : props.theme.colors.blue
  }};
`
export const Maincontainer = styled.div<any>`
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  /* height: 100vh; */
  align-items: center;
  background-color: ${(props) => {
    if (props.currNetwork === 'Solana') {
      return props.mode ? 'black' : props.theme.colors.white
    } else {
      return props.mode ? props.theme.colors.black : props.theme.colors.white
    }
  }};

  /* background-color: red; */
`
export const Card = styled.div<any>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 360px;
    flex-direction: column;
    display: flex;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 390px) {
    width: 285px;
  }
  @media (max-width: 319px) {
    width: 262px;
  }
  @media (width: 1024px) {
    width: 700px;
  }
  background-color: ${(props) => {
    if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
      return props.mode ? '#191919' : 'white'
    } else {
      return props.mode ? 'white' : 'white'
    }
  }};

  .withinc {
    height: 24px;
    text-align: left;
    font-weight: 600;
    font-family: monospace;
  }
  .discount-container {
    color: ${(props) => {
      return props.mode ? '#FFFFFF' : '#000022'
    }};

    display: none;
  }
`
export const SideNav = styled.div<any>`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 225px;
  @media (max-width: 360px) {
    width: 100px;
  }
  border-right: 2px solid lightgray;
  background-color: ${(props) => {
    if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
      return props.mode ? 'rgb(24,24,24)' : 'rgb(241,242,243)'
    } else {
      return props.mode ? 'rgb(20,20,47)' : 'rgb(241,242,243)'
    }
  }};
  ${(props) =>
    props.mode &&
    css`
      border: none;
    `}
  div {
    padding: 10px;
    color: ${(props) => {
      return props.mode ? props.theme.colors.black : 'rgb(16, 16, 63)'
    }};
  }
  a {
    text-decoration: none;
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? props.theme.colors.white : 'black'
      } else {
        return props.mode ? props.theme.colors.gold : 'black'
      }
    }};
  }
  a:hover {
    color: black;
  }
  .dasscolumn {
    cursor: pointer;
    font-size: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
  }
  .dasscolumn:hover {
    transform: translateY(-5px);
    background-color: white;
    border-radius: 5px;
  }
  .dasscolumn img {
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
    margin-left: 0%;
    margin-top: 60%;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #993399;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #d2ab65;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(33px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`

export const TopNav = styled.div<any>`
  height: 80px;
  margin-top: 50px;

  #levelone {
    background-color: ${(props) => {
      return props.lvlone && '#b64ef9;'
    }};
  }
  #leveltwo {
    background-color: ${(props) => {
      return props.lvltwo && '#b64ef9;'
    }};
  }
  #levelthree {
    background-color: ${(props) => {
      return props.lvlthree && '#b64ef9;'
    }};
  }

  .progressbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    width: 420px;
    margin: 0 auto;
    @media (max-width: 700px) {
      width: 225px;
      padding-bottom: 4px;
      margin: auto;
    }
  }
  .progressbtn div {
    height: 10px;
    width: 10px;
    /* background-color: red; */
    border-radius: 50%;
    border: 1px;
    border-style: solid;
    border-color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'gray'
    }};
    @media (max-width: 700px) {
      width: 15px;
    }
  }
  .progressbtn .rectangle {
    height: 1px;
    width: 5px;
    border-radius: 0%;
    width: 200px;
    border: none;
    @media (max-width: 360px) {
      width: 2.5;
    }
    background-color: ${(props) => {
      return props.mode ? 'rgb(241,242,243)' : 'gray'
    }};
  }
  #progresstittle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => {
      return props.mode ? 'black' : 'white'
    }};
    width: 450px;
    margin: 0 auto;
    @media (max-width: 700px) {
      width: 250px;
    }
  }
  #progresstittle .subtittle {
    font-weight: 600;
    font-style: italic;
    font-size: 12px;
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? '#c8c8c8' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
    @media (max-width: 700px) {
      font-size: 10px;
    }
  }
`
export const LogoContainer = styled.div`
  height: 140px;
  width: 100%;
  border-bottom: 1px solid rgb(212, 208, 208);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: 60px;
  }
  @media (max-width: 700px) {
    height: 60px;
    img {
      width: 50px;
      margin: 10px 0;
    }
  }
`
export const CardChild = styled.div<any>`
  margin: 0%;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 12px;
  }
  @media (min-width: 701px) and (max-width: 1023px) {
    margin-bottom: 10px;

    /* height:720px; */
  }
  @media (min-width: 1024px) and (max-width: 1366px) {
    height: auto;
  }
  background-color: ${(props) => {
    if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
      return props.mode ? props.theme.colors.grey : 'white'
    } else {
      return props.mode ? props.theme.colors.white : 'white'
    }
  }};
  border-radius: 7px;

  .tokenselector {
    background-color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? props.theme.colors.grey : 'white'
      } else {
        return props.mode ? props.theme.colors.white : 'white'
      }
    }};
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
    padding: 10px 20px;
    margin: 20px auto;
    border-radius: 5px;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #ff3399;
    position: relative;
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    bottom: 7px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid;
    border-color: grey;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #0dddd7;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .serviceFee-container {
    font-size: 14px;
    display: flex;
    background-color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? props.theme.colors.grey : 'white'
      } else {
        return props.mode ? props.theme.colors.white : 'white'
      }
    }};

    margin: 5px 0px;
  }
  .sf-block {
    padding: 7px;
    text-align: center;
    border: solid;
  }
  .text2 {
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
  }
  .text3 {
    font-style: italic;
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
  }
  .bold {
    font-weight: bold;
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
  }
  .bold2 {
    font-weight: bold;
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'white' : 'black'
      }
    }};
    font-style: italic;
  }

  .br {
    border-right: 1px solid black;
  }

  .heading {
    font-size: 14px;
    font-weight: 700;
    color: rgb(0, 0, 33);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    height: 40px;
  }
  .heading p {
    @media (max-width: 700px) {
      font-size: 9px;
    }
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      } else {
        return props.mode ? 'black' : 'lightgray'
      }
    }};
  }

  .inputcontainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: red; */
    width: 300px;
    @media (max-width: 700px) {
      width: 235px;
    }
    @media (max-width: 319px) {
      width: 186px;
    }
  }
  .paywith {
    font-size: 20px;
    font-weight: 700;
    padding-right: 20px;
  }
  .inputbtn {
    cursor: pointer;
    height: 12px;
    width: 60px;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    justify-content: center;
    /* background: linear-gradient(to right, #50c59e,#ba77b2); */
    background: ${(props) => (props.mode ? '#323232' : 'white')};

    align-items: center;
    color: ${(props) => (props.mode ? 'white' : 'black')};
    box-shadow: grey 2px 2px 2px;
    @media (max-width: 700px) {
      height: 2px;
      width: 43px;
      font-size: 9px;
      margin-left: 7px;
    }
  }

  .tokenaddress {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #dbdbdb;
    background: ${(props) => (props.mode ? '#323232' : 'white')};
    @media (max-width: 700px) {
      font-size: 10px;
      font-weight: 900;
      /* font-weight: 00; */
    }
  }
  @media (max-width: 700px) {
    .tokenaddress p {
      padding-bottom: 6px;
    }
  }
  .golden {
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? '#cc00cc' : '#d2ab65'
      }
    }};
  }
  .upload {
    color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? 'white' : 'black'
      }
    }};
    cursor: pointer;
  }

  #tokenmsg {
    font-size: 12px;
    padding: 5px;
    color: #d2ab65;
  }
  #receiptmsg {
    font-size: 12px;
    color: red;
    text-align: center;
    margin: 2px;
  }
  #approvemsg {
    font-size: 12px;
    text-align: center;
    margin: 2px;
  }
  #transfermsg {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 7vh;
    word-wrap: break-word;
    height: 20vh;
    width: 80%;
    background-color: gray;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
  }
  .inputToken {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid
      ${(props) => {
        if (
          props.currNetwork === 'Solana' ||
          props.currNetwork === 'SolanaDev'
        ) {
          return props.mode ? '#ff3399' : '#d2ab65'
        } else {
          return props.mode ? 'white' : '#d2ab65'
        }
      }};
    background-color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? '#333333' : 'white'
      } else {
        return props.mode ? 'lightgray' : 'white'
      }
    }};
    border-radius: 5px;
    overflow-y: scroll;
    width: 100%;
    padding: 0;
  }
  .inputToken::-webkit-scrollbar {
    width: 5px;
  }

  .inputToken::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #d2ab65;
  }
  .inputToken::-webkit-scrollbar-thumb:hover {
    background-color: #c79537;
  }

  .inputToken:focus {
    outline: none;
  }
  .widthinc {
    width: 100%;
    height: 24px;
    border-radius: 0 0 5px 5px;
    color: white;
    font-weight: 600;
    @media (max-width: 700px) {
      font-size: 10px;
      padding: 0 2px;
    }
  }
  textarea#recipient-data {
    width: 100%;
    color: white;
    font-weight: 600;
  }
  .approvebtn {
    height: 35px;
    padding: 5px;
    cursor: pointer;
    @media (max-width: 700px) {
      padding: 5px;
      cursor: pointer;
      font-size: 13px;
    }
    background-color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? '#b64ef9' : '#b64ef9'
      } else {
        return props.mode ? 'rgb(0, 0, 33)' : 'lightgray'
      }
    }};

    color: black;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    width: 100%;
    /* height: 40px; */
    font-weight: 500;
  }

  .approvebtn3 {
    height: 35px;
    padding-top: 20px;
    padding-bottom: 50px;
    cursor: pointer;
    @media (max-width: 700px) {
      padding: 5px;
      cursor: pointer;
      font-size: 13px;
    }
    background-color: ${(props) => {
      if (props.currNetwork === 'Solana' || props.currNetwork === 'SolanaDev') {
        return props.mode ? '#323232' : '#b64ef9'
      } else {
        return props.mode ? 'rgb(0, 0, 33)' : 'lightgray'
      }
    }};

    color: white;
    font-size: 18px;
    border-top: none;
    border-left: none;
    border-bottom: 3px solid #b64ef9;
    border-radius: 4px;
    width: 100%;
    /* height: 40px; */
    font-weight: 500;
  }
  .tablecontainer table {
    width: 98%;
    margin: 0 auto;
  }
  .tablecontainer table th {
    border-bottom: 2px solid #fff;
  }
  .tablecontainer {
    width: 100%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    table-layout: fixed;
    background: rgb(210, 171, 101);
    border-radius: 5px;
  }
  .tablecontainer::-webkit-scrollbar {
    width: 2px;
  }

  .tablecontainer::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #d2ab65;
  }
  .tablecontainer::-webkit-scrollbar-thumb:hover {
    background-color: #c79537;
  }
  .tabledetails td {
    width: auto;
    padding: 10px 0 10px 0;
    font-size: 14px;
  }
  td#data {
    font-weight: 800;
    text-align: right;
    width: 200px;
  }
  .tabledetails {
    width: 100%;
    margin-bottom: 20px;
  }
  body {
    background: #000;
  }
  .tablecontainer tr {
    font-size: 14px;
    height: 30px;
    /* line-height: 20px; */
  }

  .tablecontainer table:nth-child(even) {
    background-color: #fff;
  }
  .transferbtn {
    /* margin-left: 40%; */
    cursor: pointer;
    background-color: rgb(0, 0, 33);
    width: 100%;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    padding: 8px;
  }
  .sectionarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  p.Heading1 {
    font-size: 20px;
    text-align: left;
    float: left;
    width: 100%;
    font-weight: 600;
  }
  .paymentoption .payopt {
    cursor: pointer;
    box-shadow: grey 2px 2px 2px;
    /* background-image: linear-gradient(to right, #50c59e,#ba77b2); */
    background-color: gray;
    margin: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 60px;
    padding: 5px;
    @media (max-width: 700px) {
      font-size: 10px;
      padding: 5px 2px;
      margin: 10px 4px;
    }
    @media (max-width: 319px) {
      font-size: 10px;
      padding: 5px 2px;
    }
    color: ${(props) => {
      return props.mode ? 'white' : 'black'
    }};
  }
  .payopt img {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
  .payopt:hover {
    background-color: #00ff99;
    box-shadow: grey 2px 2px 2px;
    color: black;
  }
`
export const TopNavButton = styled.div<any>`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  box-sizing: border-box;
  height: 5vh;
  padding: 10px;
  font-weight: 600;
  @media (max-width: 700px) {
    padding: 9px;
  }
  @media (max-width: 300px) {
    padding: 7px;
  }
  #varifiactionbtn {
    cursor: pointer;
    margin-right: 50px;
    margin-left: 10px;
    font-size: 12px;
    color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'black'
    }};
    @media (max-width: 700px) {
      font-size: 10px;
      margin-right: 28px;
      margin-left: -3px;
    }
    @media (max-width: 300px) {
      font-size: 8px;
    }
  }
  #analyticsbtn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 12px;
    /* border-right: 1px solid;
    border-right-color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'black'
    }}; */
    height: 8px;
    margin-right: 10px;
    color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'black'
    }};
    @media (max-width: 700px) {
      font-size: 10px;
      padding: 10px 9px;
      margin-right: 22px;
      display: none;
    }
    @media (max-width: 300px) {
      font-size: 8px;
      margin-right: 18px;
    }
  }

  .drop {
    border: 2px solid #993399;
    cursor: pointer;
    font-weight: 600;
    color: black;
    background-color: #b64ef9;
    border-radius: 5px;
    min-width: 105px;
    font-size: 12px;
    padding: 10px;
    @media (max-width: 700px) {
      display: block;
      min-width: 72px;
      font-size: 10px;
      margin-left: -50px;
      padding: 5px 0 6px 0;
    }
    @media (max-width: 319px) {
      min-width: 50px;
      font-size: 9px;
      margin-left: -20px;
      padding: 6px 0;
    }
  }

  .dropbtn {
    border: 2px solid #993399;
    cursor: pointer;
    font-weight: 600;
    color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'black'
    }};
    background-color: #b64ef9;
    border-radius: 5px;
    min-width: 105px;
    font-size: 12px;
    padding: 10px;
    @media (max-width: 700px) {
      display: block;
      min-width: 72px;
      font-size: 10px;
      margin-left: -50px;
      padding: 5px 0 6px 0;
    }
    @media (max-width: 319px) {
      min-width: 50px;
      font-size: 9px;
      margin-left: -20px;
      padding: 6px 0;
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
    @media (max-width: 700px) {
      margin-right: 10px;
      display: block;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    @media (max-width: 700px) {
      min-width: 122px;
    }
  }
  .dropdown-content div {
    cursor: pointer;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    @media (max-width: 700px) {
      font-size: 10px;
      padding: 9px 12px;
    }
  }
  .dropdown-content div:hover {
    color: black;
  }
  .dropdown-content div:hover {
    background-color: #f1f1f1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
    transform: translateX(0px);
    @media (max-width: 700px) {
      right: -20%;
      margin-top: 5px;
      display: block;
    }
  }

  .dropbtn1 {
    border: 2px solid #993399;
    cursor: pointer;
    font-weight: 600;
    color: ${(props) => {
      return props.mode ? 'black' : 'black'
    }};
    background-color: inherit;
    border-radius: 5px;
    min-width: 105px;
    font-size: 12px;
    padding: 10px;
    @media (max-width: 700px) {
      display: block;
      min-width: 72px;
      font-size: 10px;
      margin-left: -20px;
      padding: 5px 0 6px 0;
    }
    @media (max-width: 319px) {
      min-width: 50px;
      font-size: 9px;
      margin-left: -20px;
      padding: 6px 0;
    }
  }
  .dropdown1 {
    position: relative;
    display: inline-block;
    @media (max-width: 700px) {
      margin-right: 10px;
    }
  }

  .dropdown-content1 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    @media (max-width: 700px) {
      min-width: 122px;
    }
  }
  .dropdown-content1 div {
    cursor: pointer;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: none;
    font-size: 14px;
    @media (max-width: 700px) {
      font-size: 10px;
      padding: 9px 12px;
      display: none;
    }
  }
  .dropdown-content1 div:hover {
    color: black;
  }
  .dropdown-content1 div:hover {
    background-color: #f1f1f1;
  }
  .dropdown1:hover .dropdown-content1 {
    display: block;
    transform: translateX(0px);
    @media (max-width: 700px) {
      right: -20%;
      margin-top: 5px;
      display: block;
    }
  }

  .dropdown1:hover .dropdown-content1 div {
    display: block;
    transform: translateX(0px);
    @media (max-width: 700px) {
      right: -20%;
      margin-top: 5px;
      display: block;
    }
  }

  .dropbtn2 {
    border: none;
    cursor: pointer;
    font-weight: 600;
    color: ${(props) => {
      return props.mode ? props.theme.colors.white : 'black'
    }};
    background-color: inherit;
    border-radius: 5px;
    min-width: 105px;
    font-size: 12px;
    padding: 10px;
    @media (max-width: 700px) {
      display: block;
      min-width: 72px;
      font-size: 10px;
      margin-left: 30px;
      margin-right: 50px;
      padding: 5px 0 6px 0;
    }
    @media (max-width: 319px) {
      min-width: 50px;
      font-size: 9px;
      padding: 6px 0;
      margin-left: -24px;
      margin-right: -45px;
    }
  }
  .dropdown2 {
    position: relative;
    display: none;

    @media (max-width: 700px) {
      margin-right: 87px;
      width: 7px;
      display: block;
    }
  }

  .dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #191919;
    min-width: 160px;
    z-index: 1;
    @media (max-width: 700px) {
      min-width: 122px;
      margin-right: -40px;
    }
  }
  .dropdown-content2 div {
    cursor: pointer;
    color: white;
    font-weight: 600;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 14px;

    @media (max-width: 700px) {
      font-size: 10px;
      padding: 9px 12px;
    }
  }

  .dropdown2:hover .dropdown-content2 {
    display: block;
    transform: translateX(0px);
    @media (max-width: 700px) {
      right: -20%;
      margin-top: 5px;
      display: block;
    }
  }
`
