import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 8%;
  left: 0%;
  height: 152vh;
  width: 100%;
  background-color: black;
  display: flex;
  place-content: center;
  place-items: center;
  @media (min-width: 701px) and (max-width: 1024px) {
    top: 6%;
  }
  @media (max-width: 700px) {
    top: 9%;
  }
  @media (min-width: 1280px) and (max-width: 1339px) {
    top: 9%;
  }
`

export const FormContainer = styled.div`
  height: 60%;
  width: 30%;
  background-color: #191919;
  border-radius: 8px;
  @media (max-width: 700px) {
    width: 95%;
    position: absolute;
    top: 5%;
  }
  @media (min-width: 701px) and (max-width: 1024px) {
    width: 60%;
    position: absolute;
    top: 4%;
    height: 50%;
    padding: 10px 0;
  }
  @media (min-width: 1025px) and (max-width: 1300px) {
    width: 40%;
  }
  a {
    text-decoration: none;
  }
  a img {
    margin-top: 7%;
  }
`

export const HeadingContainer = styled.h3`
  margin-top: 5%;

  font-weight: 600;
  color: white;
`
export const FormDiv = styled.form`
  margin-top: 7%;
  display: flex;
  justify-content: space-evenly;
  height: 70%;
  align-items: center;
  flex-direction: column;
`

export const FormRow = styled.div`
  height: auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.txt &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `};

  label {
    color: white;
    margin-bottom: 3%;
    font-weight: 600;
    @media (min-width: 280px) and (max-width: 416px) {
      font-size: 12px;
    }
    @media (max-width: 300px) {
      font-size: 10px;
    }
  }
  textarea:focus {
    outline: gray;
  }

  textarea {
    border-radius: 5px;
    background-color: grey;
    color: white;
    width: 100%;
  }

  input {
    border-radius: 5px;
    background-color: grey;
    height: 20px;
    color: white;
    @media (min-width: 280px) and (max-width: 416px) {
      width: 125px;
    }
  }
  input:focus {
    outline: none;
  }
`

export const BtnDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
`
export const SubmitBtn = styled.input`
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  display: flex;
  border: 2px solid rgb(153, 51, 153);
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  background-color: inherit;
  color: white;
  font-weight: 600;
  @media (max-width: 700px) {
    margin-right: 10px;
  }
`
export const CloseBtn = styled.button`
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  display: flex;
  border: 2px solid rgb(153, 51, 153);
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  background-color: inherit;
  color: white;
  font-weight: 600;
`
