import useStore from '../backend/store'
import { validateWallet, WStatus } from '../api/connect-wallet'

export default function SignIn({
  ischecked,
  setIsChecked,
  toggleModal,
  setToggleModal,
  network,
}: {
  ischecked: boolean
  setIsChecked: (_: boolean) => void
  toggleModal: boolean
  setToggleModal: (_: boolean) => void
  network: string
}) {
  const publicKey = useStore((s) => s.publicKey)
  const privateKey = useStore((s) => s.privateKey)
  const setPublicKey = useStore((s) => s.setPublicKey)
  const setPrivateKey = useStore((s) => s.setPrivateKey)
  const isSubscribed = useStore((s) => s.isSubscribed)
  const setIsSubscribed = useStore((s) => s.setIsSubscribed)

  const onValidateWallet = async () => {
    const status: WStatus = await validateWallet(publicKey, privateKey, network)
    if (status === WStatus.Valid) {
      setIsChecked(true)
      setToggleModal(!toggleModal)
      setIsSubscribed(true)
    } else if (status === WStatus.NotSubscribed) {
      setIsChecked(true)
      setIsSubscribed(false)
    } else {
      setIsChecked(false)
      setIsSubscribed(false)
    }
  }

  return (
    <>
      <div className="nowalletmodal">
        <p id="importantinfo">
          Important : We <b> DO NOT</b> store the private key of the wallet/s.
          We suggest that you create a secondary wallet to be used only for the
          airdrop transaction.
        </p>
        <div className="submodal">
          <div className="inputsc">
            <p>
              Wallet Address:{' '}
              <input
                type="text"
                id="publicKey"
                required
                onChange={(e) => {
                  setPublicKey(e.target.value)
                }}
              />
            </p>
            <p>
              Private Key:{' '}
              <input
                type="text"
                id="seckey"
                required
                onChange={(e) => {
                  setPrivateKey(e.target.value)
                }}
              />
            </p>
          </div>

          {ischecked && !isSubscribed ? (
            <div>
              <p style={{ color: 'white' }}>
                You are not a subscriber,{' '}
                <button
                  className="modalswitcher"
                  onClick={() =>
                    window.open('https://subscriptions-solana.vercel.app/')
                  }
                >
                  Subscribe
                </button>
              </p>
            </div>
          ) : null}

          <div className="buttonauto">
            <button
              className="modalswitcher"
              onClick={() => {
                onValidateWallet()
              }}
            >
              Submit
            </button>
            <button
              className="modalswitcher2"
              onClick={() => {
                setToggleModal(!toggleModal)
                setPublicKey('')
                setPrivateKey('')
                setIsChecked(false)
                setIsSubscribed(false)
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
