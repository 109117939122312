import {
  Keypair,
  Transaction,
  LAMPORTS_PER_SOL,
  SystemProgram,
} from '@solana/web3.js'
import * as bs58 from 'bs58'
import {
  Token,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token'

import { Connection, PublicKey } from '@solana/web3.js'
import { fEesCollector } from './fees'
import { TokenListProvider } from '@solana/spl-token-registry'
import * as metaplexapi from '@metaplex/js'

let network = 'https://api.metaplex.solana.com/'

export const setDevnet = () => {
  network = 'https://api.devnet.solana.com'
}

export const setMainnet = () => {
  network = 'https://api.metaplex.solana.com/'
}

export const spltransferToken = async (
  _receiver,
  _money,
  _decimals,
  _mint,
  _private_key
) => {
  const connection = new Connection(network)

  let amnt = _money * Math.pow(10, _decimals)

  let payer
  try {
    let _seckey = bs58.decode(_private_key)
    let pkey = Uint8Array.from(_seckey)
    payer = Keypair.fromSecretKey(pkey)
  } catch {
    let pkey = Uint8Array.from(JSON.parse(_private_key))
    payer = Keypair.fromSecretKey(pkey)
  }
  let multisigner = {
    publicKey: payer.publicKey,
  }
  let transaction = new Transaction()
  let mint = new PublicKey(_mint)
  let receiver = new PublicKey(_receiver)
  let sendertokenaccount
  let sources = await connection.getTokenAccountsByOwner(payer.publicKey, {
    mint,
  })
  if (sources.value.length !== 0) {
    sendertokenaccount = sources.value[0].pubkey
  } else {
    alert('You can only transfer token that you own ')
    return { value: false, sign: '' }
  }

  let receivertokenaccount
  let receivers = await connection.getTokenAccountsByOwner(receiver, { mint })

  if (receivers.value.length !== 0) {
    receivertokenaccount = receivers.value[0].pubkey
  } else {
    let associated_account = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      receiver
    )
    receivertokenaccount = associated_account

    let token_instruction = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      associated_account,
      receiver,
      payer.publicKey
    )

    transaction.add(token_instruction)
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    sendertokenaccount,
    mint,
    receivertokenaccount,
    payer.publicKey,
    [multisigner],
    amnt,
    _decimals
  )

  transaction.add(token_transfer_instruction)
  transaction.feePayer = payer.publicKey
  let blockhashObj = await connection.getRecentBlockhash()
  transaction.recentBlockhash = blockhashObj.blockhash

  try {
    let sig = await connection.sendTransaction(transaction, [payer])

    return { value: true, sign: sig }
  } catch (err) {
    console.log(err)
    return { value: false, sign: '' }
  }
}

export const nfttransfer = async (_receiver, _mint, _private_key) => {
  let connection = new Connection(network)
  let payer
  try {
    let _seckey = bs58.decode(_private_key)
    let pkey = Uint8Array.from(_seckey)
    payer = Keypair.fromSecretKey(pkey)
  } catch {
    let pkey = Uint8Array.from(JSON.parse(_private_key))
    payer = Keypair.fromSecretKey(pkey)
  }

  let transaction = new Transaction()
  let mint = new PublicKey(_mint)
  let sendertokenaccount

  let sources = await connection.getTokenAccountsByOwner(payer.publicKey, {
    mint,
  })
  if (sources.value.length !== 0) {
    sendertokenaccount = sources.value[0].pubkey
  } else {
    alert('You can only transfer token that you own ')
    return false
  }
  let receivertokenaccount
  let receiver = new PublicKey(_receiver)
  let receivers = await connection.getTokenAccountsByOwner(receiver, { mint })

  if (receivers.value.length !== 0) {
    receivertokenaccount = receivers.value[0].pubkey
  } else {
    let associated_account = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      receiver
    )
    receivertokenaccount = associated_account

    let token_instruction = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mint,
      associated_account,
      receiver,
      payer.publicKey
    )

    transaction.add(token_instruction)
  }
  let multisigner = {
    publicKey: payer.publicKey,
  }
  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    sendertokenaccount,
    mint,
    receivertokenaccount,
    payer.publicKey,
    [multisigner],
    1,
    0
  )

  transaction.add(token_transfer_instruction)
  transaction.feePayer = payer.publicKey
  let blockhashObj = await connection.getRecentBlockhash()
  transaction.recentBlockhash = await blockhashObj.blockhash

  try {
    let sig = await connection.sendTransaction(transaction, [payer])
    return { value: true, sign: sig }
  } catch (err) {
    console.log(err)
    return { value: false, sign: '' }
  }
}

export const converter = async (_amount, _paymentMethod) => {
  let one_sol_to_usd
  let one_sol_to_usd_response
  let one_sol_to_usd_value
  let one_usd_to_sol
  let usd_to_sol
  let usd_to_lamports
  if (_paymentMethod === 'usdt') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.tether.usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else if (_paymentMethod === 'usdc') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response['usd-coin'].usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.solana.usd

    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * LAMPORTS_PER_SOL

    return usd_to_lamports
  }

  // _amount: usd
  //   1 sol 253
  //
}

export const feeCalculator = async (_recivers, _paymentMethod) => {
  const feeCollector = new PublicKey(fEesCollector)
  let amount
  if (_recivers.length >= 1 && _recivers.length <= 99) {
    amount = await converter(25, _paymentMethod)
  } else if (_recivers.length >= 100 && _recivers.length <= 249) {
    amount = await converter(50, _paymentMethod)
  } else if (_recivers.length >= 250 && _recivers.length <= 499) {
    amount = await converter(75, _paymentMethod)
  } else if (_recivers.length >= 500 && _recivers.length <= 999) {
    amount = await converter(100, _paymentMethod)
  } else {
    amount = await converter(750)
  }

  if (_paymentMethod === 'usdt') {
    let ins = await payWithUSDT(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )

    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(ins)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      let sig = await connection.sendRawTransaction(
        newsignedTransaction.serialize()
      )
      await connection.confirmTransaction(sig)
      return { value: true, sign: sig }
    } catch (err) {
      console.log(ins)
      return { value: false, sign: '' }
    }
  }
  if (_paymentMethod === 'usdc') {
    let ins = await payWithUSDC(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )
    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(ins)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      let sig = await connection.sendRawTransaction(
        newsignedTransaction.serialize()
      )
      await connection.confirmTransaction(sig)
      return { value: true, sign: sig }
    } catch (err) {
      console.log(ins)
      return { value: false, sign: '' }
    }
  } else {
    let feeInstruction = SystemProgram.transfer({
      fromPubkey: window.solana.publicKey,
      lamports: amount,
      toPubkey: feeCollector,
    })
    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(feeInstruction)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      let sig = await connection.sendRawTransaction(
        newsignedTransaction.serialize()
      )

      await connection.confirmTransaction(sig)

      return { value: true, sign: sig }
    } catch (err) {
      console.log(err)
      return { value: false, sign: '' }
    }
  }
}

export const feeCalculatorSolana = async (
  _recivers,
  _paymentMethod,
  _private_key
) => {
  const feeCollector = new PublicKey(fEesCollector)
  let amount
  if (_recivers.length >= 1 && _recivers.length <= 99) {
    amount = await converter(25, _paymentMethod)
  } else if (_recivers.length >= 100 && _recivers.length <= 249) {
    amount = await converter(50, _paymentMethod)
  } else if (_recivers.length >= 250 && _recivers.length <= 499) {
    amount = await converter(75, _paymentMethod)
  } else if (_recivers.length >= 500 && _recivers.length <= 999) {
    amount = await converter(100, _paymentMethod)
  } else {
    amount = await converter(750)
  }

  let payer
  try {
    let _seckey = bs58.decode(_private_key)
    let pkey = Uint8Array.from(_seckey)
    payer = Keypair.fromSecretKey(pkey)
  } catch {
    let pkey = Uint8Array.from(JSON.parse(_private_key))
    payer = Keypair.fromSecretKey(pkey)
  }

  if (_paymentMethod === 'usdt') {
    let result = await payWithUSDT(payer.publicKey, fEesCollector, amount)

    if (result.status === false) {
      return { value: false, sign: '' }
    }

    let connection = new Connection(network)
    let transactions = new Transaction()
    if (result.insList.length === 1) {
      transactions.add(result.insList[0])
    } else {
      transactions.add(result.insList[0])
      transactions.add(result.insList[1])
    }
    transactions.feePayer = payer.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = blockhashObj.blockhash

    try {
      let sig = await connection.sendTransaction(transactions, [payer])

      await connection.confirmTransaction(sig)
      return { value: true, sign: sig }
    } catch (err) {
      console.log(err)
      return { value: false, sign: '' }
    }
  }
  if (_paymentMethod === 'usdc') {
    let result = await payWithUSDC(payer.publicKey, fEesCollector, amount)

    if (result.status === false) {
      return { value: false, sign: '' }
    }

    let connection = new Connection(network)
    let transactions = new Transaction()

    if (result.insList.length === 1) {
      transactions.add(result.insList[0])
    } else {
      transactions.add(result.insList[0])
      transactions.add(result.insList[1])
    }

    transactions.feePayer = payer.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = blockhashObj.blockhash
    try {
      let sig = await connection.sendTransaction(transactions, [payer])
      await connection.confirmTransaction(sig)
      return { value: true, sign: sig }
    } catch (err) {
      console.log(err)
      return { value: false, sign: '' }
    }
  } else {
    let feeInstruction = SystemProgram.transfer({
      fromPubkey: payer.publicKey,
      lamports: amount,
      toPubkey: feeCollector,
    })
    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(feeInstruction)
    transactions.feePayer = payer.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = blockhashObj.blockhash
    try {
      let sig = await connection.sendTransaction(transactions, [payer])

      await connection.confirmTransaction(sig)

      return { value: true, sign: sig }
    } catch (err) {
      console.log(err)
      return { value: false, sign: '' }
    }
  }
}

export const payWithUSDT = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  // let new_token_instruction
  let insList = []

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      // alert('You can only transfer token that you own')
      return
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    // alert('something went wrong')
    // alert('Server Timeout')
    return { insList, status: false }
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      // new_token_instruction = token_instruction
      insList.push(token_instruction)
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    // alert('something went wrong')
    // alert('Server Timeout')
    return { insList, status: false }
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  insList.push(token_transfer_instruction)

  return { insList, status: true }
}

export const payWithUSDC = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  // let new_token_instruction
  let insList = []

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      // alert('You can only transfer token that you own')
      return
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    // alert('Server Timeout')
    return { insList, status: false }
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      // new_token_instruction = token_instruction
      insList.push(token_instruction)
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    // alert('Server Timeout')
    return { insList, status: false }
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  insList.push(token_transfer_instruction)

  return { insList, status: true }
}

// minor changes starts

export const getuserspltoken = async (user) => {
  // export const getuserspltoken = async () => {
  let connection = new Connection(network)

  // let connection = new Connection("https://api.mainnet-beta.solana.com");
  let chainid

  if (connection._rpcEndpoint === 'https://api.devnet.solana.com') {
    chainid = 103
  } else {
    chainid = 101
  }

  let _user = new PublicKey(user)

  let tokens = await connection.getParsedTokenAccountsByOwner(_user, {
    programId: TOKEN_PROGRAM_ID,
  })
  let token_list = (await new TokenListProvider().resolve()).filterByChainId(
    chainid
  )

  let all_token_list = token_list.getList()

  let valid_token = []

  let tempuser = new PublicKey('42YykCsT67FSfqbtz6v7YLR8S5fqX3yT8sygX1k6uJQt')

  let usermetaplexnft =
    await metaplexapi.programs.metadata.Metadata.findDataByOwner(
      connection,
      tempuser
    )

  let nftmint_array = []

  usermetaplexnft.forEach((item) => {
    nftmint_array.push(item.mint)
  })

  let final_array = []

  for (let i = 0; i < tokens.value.length; i++) {
    let exit = nftmint_array.includes(
      tokens.value[i].account.data.parsed.info.mint
    )

    if (exit === false) {
      let _mint = new PublicKey(tokens.value[i].account.data.parsed.info.mint)

      try {
        let getmetadata =
          await metaplexapi.programs.metadata.Metadata.findByMint(
            connection,
            _mint
          )

        if (getmetadata.data.data.uri === '') {
          final_array.push(tokens.value[i])
        }
      } catch (err) {
        final_array.push(tokens.value[i])
      }
    }
  }

  final_array.forEach((item) => {
    if (Number(item.account.data.parsed.info.tokenAmount.amount) > 0) {
      let decimals
      let amount
      let token_mint
      let name
      let uri

      let required_token = all_token_list.filter((ritem) => {
        return item.account.data.parsed.info.mint === ritem.address
      })

      if (required_token.length !== 0) {
        decimals = item.account.data.parsed.info.tokenAmount.decimals
        amount = item.account.data.parsed.info.tokenAmount.amount
        token_mint = item.account.data.parsed.info.mint
        name = required_token[0].name
        uri = required_token[0].logoURI
      } else {
        decimals = item.account.data.parsed.info.tokenAmount.decimals
        amount = item.account.data.parsed.info.tokenAmount.amount
        token_mint = item.account.data.parsed.info.mint
        name = ''
        uri = ''
      }

      valid_token.push({ decimals, amount, token_mint, name, uri })
    }
  })

  return valid_token
}
// minor changes ends

export const nativesolanatransfer = async (
  _amount,
  _destination,
  _private_key
) => {
  let payer
  try {
    let _seckey = bs58.decode(_private_key)
    let pkey = Uint8Array.from(_seckey)
    payer = Keypair.fromSecretKey(pkey)
  } catch {
    let pkey = Uint8Array.from(JSON.parse(_private_key))
    payer = Keypair.fromSecretKey(pkey)
  }

  let feeInstruction = SystemProgram.transfer({
    fromPubkey: payer.publicKey,
    lamports: _amount * 10 ** 9,
    toPubkey: new PublicKey(_destination),
  })

  let connection = new Connection(network)
  let transaction = new Transaction()
  transaction.add(feeInstruction)
  transaction.feePayer = payer.publicKey
  let recent_blockhash = await connection.getRecentBlockhash()
  transaction.recentBlockhash = recent_blockhash.blockhash

  try {
    let sig = await connection.sendTransaction(transaction, [payer])

    return { value: true, sign: sig }
  } catch (err) {
    console.log(err)
    return { value: false, sign: '' }
  }
}

export const getsolbalance = async (pubkey) => {
  let connection = new Connection(network)
  let _pubkey = new PublicKey(pubkey)
  let balance = await connection.getBalance(_pubkey)

  return balance / 10 ** 9
}

export const confirmtx = async (tx) => {
  let connection = new Connection(network)

  try {
    let confirmedtx = await connection.getParsedTransaction(tx)

    // await sleep(2000)

    return { obj: confirmedtx }
  } catch (err) {
    console.log(err)
    return { obj: null }
  }
}

export const extractsignaturedata = (txHistory, transferarray) => {
  let finalArray = []

  transferarray.forEach((sig) => {
    txHistory.forEach((obj) => {
      if (obj.tx === sig) {
        if (obj.mint) {
          finalArray.push({ destination: obj.destination, mint: obj.mint })
        } else {
          finalArray.push({ destination: obj.destination, amount: obj.amount })
        }
      }
    })
  })

  return finalArray
}

export const formatTransactionHistory = (transactionhistory) => {
  let result = []

  transactionhistory.forEach((item) => {
    result.push(item.tx)
  })

  return result.join('\n')
}
