import { BigNumber, utils } from 'ethers'
export const BNF = BigNumber.from
export const { parseUnits, formatUnits } = utils
export const NetworkTypes1 = {
  MAINNET: 'Solana',
  DEVNET: 'SolanaDev',
}

export const TokenTypes1 = {
  SOL: 'sol',
  SPL: 'spl',
  NFT: 'nft',
}

export const PaymentMethods1 = {
  SOL: 'sol',
  USDT: 'usdt',
  USDC: 'usdc',
}

export const currTime = () => Math.floor(Date.now() / 1000)
