import Box from '@mui/material/Box'
import useStore from '../../backend/store'
import * as React from 'react'
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled'
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled'
import PopperUnstyled from '@mui/base/PopperUnstyled'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { ethers } from 'ethers'
import { TokenTypes1 } from '../../backend/utils'
import { getSolBalance } from '../../backend/solana'
import { blue, grey } from '@mui/material/colors'
const { formatUnits } = ethers.utils
const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin-top: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
)

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px 0;
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  // border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
)

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 5px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
)

const StyledPopper = styled(PopperUnstyled)`
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;
  padding: 2px;
  border-radius: 10px;
  background-color: white;
  &::-webkit-scrollbar {
    /* display: hidden; */
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const Paragraph = styled('p')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  margin: 10px 0;
  color:rgba(225,225,225,0.7);
  // color: ${theme.palette.mode === 'dark' ? grey[400] : 'white'};
  `
)

// const WraperDIV = styled('div')(
//   ({ theme }) => `
//   margin: 10px 0;
//   background-color:"black";
//   `
// )

function CustomSelect(props: SelectUnstyledProps<number>) {
  const components: SelectUnstyledProps<number>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    // @ts-ignore
    Popper: StyledPopper,
    ...props.components,
  }

  return <SelectUnstyled {...props} components={components} />
}

const NOTOKENFOUND = styled('h3')(
  ({ theme }) => `
  font-size:20px;
  color: ${theme.palette.mode === 'dark' ? grey[400] : grey[700]};
  `
)

export default function SelectToken() {
  const network = useStore((s) => s.network)
  const publicKey = useStore((s) => s.publicKey)
  const tokenType = useStore((state) => state.tokenType)
  const setTokenAddr = useStore((state) => state.setTokenAddr)
  const setTokenDecimals = useStore((s) => s.setTokenDecimals)
  const userSplTokenList = useStore((state) => state.userSplTokenList)
  const [solBal, setSolBal] = React.useState('0')

  React.useEffect(() => {
    ;(async () => {
      const _solBal = await getSolBalance(network, publicKey)
      setSolBal(_solBal)
    })()
  }, [network, publicKey])

  return (
    <>
      <Box
        sx={{
          visibility: tokenType === TokenTypes1.SOL ? 'visible' : 'hidden',
        }}
      >
        <Typography variant="h6" color="white" align="left">
          Balance:{' '}
          <span style={{ color: '#4ADCD6' }}>
            {Number(solBal).toFixed(3)} SOL
          </span>
        </Typography>
      </Box>

      {tokenType !== TokenTypes1.SPL ? (
        <Paragraph>
          Tip: Dont forget to verify your signatures on our verification section
          for confirmed transaction.This will save you from sending token more
          than the desired amount.
        </Paragraph>
      ) : (
        <Box
          sx={{
            visibility: tokenType === TokenTypes1.SPL ? 'visible' : 'hidden',
          }}
        >
          <Typography variant="h6" color="white" align="left">
            Select Token
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomSelect
              // value={30}
              // defaultValue={
              //   userSplTokenList.length
              //     ? userSplTokenList[0].token_mint
              //     : 0
              // }
              // value={tokenAddr}
              onChange={(e) => {
                if (e == null) return
                const _idx = Number(e)
                setTokenAddr(userSplTokenList[_idx].token_mint)
                setTokenDecimals(userSplTokenList[_idx].decimals.toString())
              }}
            >
              {userSplTokenList.length > 0 ? (
                userSplTokenList.map((el, idx) => (
                  <StyledOption key={idx} value={idx} sx={{ border: 'none' }}>
                    {el.name.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          border: 'none',
                        }}
                      >
                        <img
                          width="25px"
                          style={{ marginRight: '5px' }}
                          src={el.uri}
                          alt={el.name}
                        />
                        {el.name}
                        <span
                          style={{ marginLeft: '10px' }}
                        >{`(Balance: ${formatUnits(
                          el.amount,
                          el.decimals
                        )})`}</span>
                      </Box>
                    ) : (
                      <>
                        <Typography>
                          {el.token_mint.length > 40
                            ? el.token_mint.slice(0, 10) +
                              '.....' +
                              el.token_mint.slice(34, 44)
                            : el.token_mint}
                        </Typography>
                        <span
                          style={{ marginLeft: '10px' }}
                        >{`(Balance: ${formatUnits(
                          el.amount,
                          el.decimals
                        )})`}</span>
                      </>
                    )}
                  </StyledOption>
                ))
              ) : (
                <NOTOKENFOUND>No Token Found</NOTOKENFOUND>
              )}
            </CustomSelect>
            <img height="30px" src="/solana.png" alt="solana" />
          </Box>
        </Box>
      )}
      {/* <Box
        sx={{
          visibility: tokenType === TokenTypes1.SPL ? 'visible' : 'hidden',
        }}
      >
        <Typography variant="h6" color="white" align="left">
          Select Token
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CustomSelect
            // value={30}
            // defaultValue={
            //   userSplTokenList.length
            //     ? userSplTokenList[0].token_mint
            //     : 0
            // }
            // value={tokenAddr}
            onChange={(e) => {
              if (e == null) return
              const _idx = Number(e)
              setTokenAddr(userSplTokenList[_idx].token_mint)
              setTokenDecimals(userSplTokenList[_idx].decimals.toString())
            }}
          >
            {userSplTokenList.length > 0 ? (
              userSplTokenList.map((el, idx) => (
                <StyledOption key={idx} value={idx} sx={{ border: 'none' }}>
                  {el.name.length > 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: 'none',
                      }}
                    >
                      <img
                        width="25px"
                        style={{ marginRight: '5px' }}
                        src={el.uri}
                        alt={el.name}
                      />
                      {el.name}
                      <span
                        style={{ marginLeft: '10px' }}
                      >{`(Balance: ${formatUnits(
                        el.amount,
                        el.decimals
                      )})`}</span>
                    </Box>
                  ) : (
                    <>
                      <Typography>
                        {el.token_mint.length > 40
                          ? el.token_mint.slice(0, 10) +
                            '.....' +
                            el.token_mint.slice(34, 44)
                          : el.token_mint}
                      </Typography>
                      <span
                        style={{ marginLeft: '10px' }}
                      >{`(Balance: ${formatUnits(
                        el.amount,
                        el.decimals
                      )})`}</span>
                    </>
                  )}
                </StyledOption>
              ))
            ) : (
              <NOTOKENFOUND>No Token Found</NOTOKENFOUND>
            )}
          </CustomSelect>
          <img height="30px" src="/solana.png" alt="solana" />
        </Box>
      </Box> */}
    </>
  )
}
