import create from 'zustand'
import { PaymentMethods1 } from './utils'

interface globalState {
  failedTransfers: string[]
  setFailedTransfers: (_transfer: string[]) => void

  completedTransfers: number
  setCompletedTransfers: (_transfers: number) => void

  totalTransfers: number
  setTotalTransfers: (_transfers: number) => void

  paymentMethod: string
  setPaymentMethod: (_payMethod: string) => void

  cancelled: boolean
  setCancelled: (_val: boolean) => void
}

const tokenStore = create<globalState>((set) => ({
  failedTransfers: [],
  setFailedTransfers: (_transfers) =>
    set((state) => ({ failedTransfers: _transfers })),

  completedTransfers: 0,
  setCompletedTransfers: (_transfers) =>
    set((state) => ({ completedTransfers: _transfers })),

  totalTransfers: 0,
  setTotalTransfers: (_transfers) =>
    set((state) => ({ totalTransfers: _transfers })),

  paymentMethod: PaymentMethods1.SOL,
  setPaymentMethod: (_payMethod) =>
    set((state) => ({ paymentMethod: _payMethod })),

  cancelled: false,
  setCancelled: (_val) => set((state) => ({ cancelled: _val })),
}))

export default tokenStore
