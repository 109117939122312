import { Connection, PublicKey } from '@solana/web3.js'

// default values
let network = 'https://api.mainnet-beta.solana.com'
let program = 'A2rZzPagaCPgrJ1NHvET4zmKfD4eLM8Ceh9tFgiYMsL'

export const setDevnetanalytics = () => {
  network = 'https://api.devnet.solana.com'
  program = 'GBzma22BQb3Qfa6PiBEis6dxSAPKVDYE2Gk4qGXqihgd'
}

export const setMainnetanalytics = () => {
  network = 'https://api.mainnet-beta.solana.com'
  program = 'A2rZzPagaCPgrJ1NHvET4zmKfD4eLM8Ceh9tFgiYMsL'
}

export const getSignature = async () => {
  let connection = new Connection(network)
  let program_address = new PublicKey(program)
  let signatures_list = await connection.getSignaturesForAddress(
    program_address
  )

  let limited_list = signatures_list.slice(0, 20)

  return limited_list
}

export const getTranactionInfo = async (_signatureObjArray) => {
  let connection = new Connection(network)
  let filterInformation = []
  for (const item of _signatureObjArray) {
    const transaction = await connection.getTransaction(item.signature)

    if (transaction.meta.preTokenBalances !== 0) {
      for (let i = 0; i < transaction.meta.postTokenBalances.length; i++) {
        let filtered_item = transaction.meta.preTokenBalances.filter(
          (item) =>
            item.accountIndex ===
            transaction.meta.postTokenBalances[i].accountIndex
        )

        if (filtered_item.length === 0) {
          filterInformation.push({
            _owner: transaction.meta.postTokenBalances[i].owner,
            token_address: transaction.meta.postTokenBalances[i].mint,
            transfer_amount:
              transaction.meta.postTokenBalances[i].uiTokenAmount.uiAmount,
            signaturehash: item.signature,
          })
        } else if (filtered_item.length !== 0) {
          let transfered =
            transaction.meta.postTokenBalances[i].uiTokenAmount.uiAmount -
            filtered_item[0].uiTokenAmount.uiAmount
          filterInformation.push({
            _owner: transaction.meta.postTokenBalances[i].owner,
            token_address: transaction.meta.postTokenBalances[i].mint,
            transfer_amount: transfered,
            signaturehash: item.signature,
          })
        }
      }
    }
  }
  return filterInformation
}

// call this to get all the receiver
export const getReceiver = (_arrayObj) => {
  let receiver = _arrayObj.filter((item) => item.transfer_amount > 0)
  return receiver
}

// call this to get sender token account info
export const getTransferTokenAmount = (_arrayObj) => {
  let senderinfo = _arrayObj.filter((item) => item.transfer_amount < 0)
  return senderinfo
}
// call this to get all the specific receiver/sender from the receiver /sender
export const getReceiverFromTransaction = (_transaction, _alldata) => {
  let receiver = _alldata.filter((item) => item.signaturehash === _transaction)
  return receiver
}

export const validateReceiver = (receivers_list, _inputreceiver, receiver) => {
  let processed_input = _inputreceiver.split(',\n')
  let processed_receiver = receiver.split('\n')

  let all_receiver = []

  processed_input.forEach((item) => {
    let value = getReceiverFromTransaction(item, receivers_list)
    value.forEach((item) => {
      let check = item._owner + ',' + item.transfer_amount.toString()
      all_receiver.push(check)
    })
  })
  let matched_receiver = []
  let unmatched_receiver = []

  for (let i = 0; i < processed_receiver.length; i++) {
    let ind = all_receiver.indexOf(processed_receiver[i])
    if (ind === -1) {
      unmatched_receiver.push(processed_receiver[i])
    } else {
      matched_receiver.push([processed_receiver[i]])
    }
  }

  let all_info = {
    all_receiver,
    matched_receiver,
    unmatched_receiver,
  }
  return all_info
}

export const getReceiverFromALLTransaction = (_receiver_data, _transaction) => {
  let processed_receiver = _transaction.split(',\n')

  let all_receiver = []
  processed_receiver.forEach((item) => {
    let value = getReceiverFromTransaction(item, _receiver_data)

    value.forEach((item) => {
      all_receiver.push(item._owner + ',' + item.transfer_amount)
    })
  })

  return all_receiver
}

export function getCSVURLSOLANA(array) {
  let csvContent = 'data:text/csv;charset=utf-8,' + array.join('\n')
  return encodeURI(csvContent)
}

export const getHeaderData = async (_filtered_data) => {
  // const connection = new Connection(network)

  const all_sig = await getSignature()

  const transaction_count = all_sig.length
  let uptime = 30
  let token_list = []
  let token_transfered = []
  const data = getTransferTokenAmount(_filtered_data)
  data.forEach((item) => {
    token_list.push(item.token_address)
    token_transfered.push(item.transfer_amount)
  })

  let sum = 0
  token_transfered.forEach((item) => {
    sum = sum + -1 * item
  })
  sum = Math.round(sum * 100) / 100

  const uniqueToken = [...new Set(token_list)]
  const totalNumberOfTokenUsed = uniqueToken.length

  return {
    sum,
    uptime,
    transaction_count,
    totalNumberOfTokenUsed,
    uniqueToken,
  }
}

export const getMintAndAmount = (_filtered_data, _unique_token) => {
  const data = getTransferTokenAmount(_filtered_data)

  let Money_array = []
  let token_signature = []

  for (let i = 0; i < _unique_token.length; i++) {
    let sum = 0
    let txn = []
    data.forEach((item) => {
      if (item.token_address === _unique_token[i]) {
        sum = sum + -1 * item.transfer_amount
        txn.push(item.signaturehash)
      }
    })

    Money_array.push(Math.round(sum * 100) / 100)
    let token = _unique_token[i]
    let obj = {
      token,
      txn,
    }
    token_signature.push(obj)
  }

  return { Money_array, token_signature, _unique_token }
}
