import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button1 } from '../Button1'
import SelectToken from './SelectToken'
import useStore from '../../backend/store'
import { NetworkTypes1, TokenTypes1 } from '../../backend/utils'
import * as fetchdata from '../../api/solana-automated'
import { useEffect } from 'react'
import { RecipientData } from './RecipientData'
import tokenStore from '../../backend/token.store'
import { useRef } from 'react'

export default function Page1({
  showinput,
  setShowInput,
  splTokenTransfer1,
  transfersolana,
  setlvlone,
  setlvltwo,
  setthree,
  nftTransfer1,
}: {
  showinput: boolean
  setShowInput: (val: boolean) => void
  splTokenTransfer1: () => void
  iscancelled: boolean
  setIscancelled: (_: boolean) => void
  setLastSignature: (_: string) => void
  transfersolana: () => void
  setlvlone: any
  setlvltwo: any
  setthree: any
  nftTransfer1: any
}) {
  const network = useStore((state) => state.network)
  const publicKey = useStore((state) => state.publicKey)
  const setPublicKey = useStore((s) => s.setPublicKey)
  const setUserSplTokenList = useStore((state) => state.setUserSplTokenList)
  const tokenType = useStore((state) => state.tokenType)
  const setTokenType = useStore((state) => state.setTokenType)
  const setTokenDecimals = useStore((state) => state.setTokenDecimals)
  const setCancelled = tokenStore((s) => s.setCancelled)
  const cancelled = tokenStore((s) => s.cancelled)
  const cancelRef = useRef<boolean>(false)
  const isSubscribed = useStore((s) => s.isSubscribed)

  useEffect(() => {
    ;(async () => {
      if (network === NetworkTypes1.DEVNET) {
        fetchdata.setDevnet()
      } else {
        fetchdata.setMainnet()
      }

      try {
        let userownedspl = await fetchdata.getuserspltoken(publicKey)
        setUserSplTokenList(userownedspl)
        setPublicKey(publicKey)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [publicKey, network])

  useEffect(() => {
    if (cancelled === true) {
      cancelRef.current = true
    } else {
      cancelRef.current = false
    }
  }, [cancelled])

  return (
    <>
      <Typography variant="h6" color="white" align="left">
        What do you want to send?
      </Typography>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Button1
          title="Solana"
          onClick={() => {
            setTokenType(TokenTypes1.SOL)
            setTokenDecimals('9')
            setShowInput(true)
          }}
          isSelected={tokenType === TokenTypes1.SOL}
        />

        <Button1
          title="SPL Token"
          onClick={() => {
            setTokenType(TokenTypes1.SPL)
            setTokenDecimals('9')
            setShowInput(true)
          }}
          isSelected={tokenType === TokenTypes1.SPL}
        />

        <Button1
          title="NFT"
          onClick={() => {
            setTokenType(TokenTypes1.NFT)
            setTokenDecimals('0')
            setShowInput(false)
          }}
          isSelected={tokenType === TokenTypes1.NFT}
        />
      </Box>

      <SelectToken />

      <RecipientData showinput={showinput} />

      <button
        className="approvebtn"
        style={{ color: 'white', marginTop: '15px' }}
        onClick={async (e) => {
          if (publicKey === '') return alert('Setup Wallet First')
          if (!isSubscribed) return alert('Buy Subscription First')

          setlvlone(false)
          setlvltwo(true)
          setthree(false)

          if (tokenType === TokenTypes1.SOL) {
            transfersolana()
          } else if (tokenType === TokenTypes1.SPL) {
            setCancelled(false)

            splTokenTransfer1()
          } else if (tokenType === TokenTypes1.NFT) {
            nftTransfer1()
          }
        }}
      >
        Transfer
      </button>
    </>
  )
}
