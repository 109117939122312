import uiStore from '../../backend/uiStore'
import txnImg from '../../assets/transaction.jpeg'
import { NetworkTypes1 } from '../../backend/utils'
import useStore from '../../backend/store'
import tokenStore from '../../backend/token.store'

import { Box, Button, Typography } from '@mui/material'

export default function Page3({
  iscancelled,
  lastSignature,
  setlvlone,
  setlvltwo,
  setthree,
}: {
  iscancelled: boolean
  lastSignature: string
  setlvlone: any
  setlvltwo: any
  setthree: any
}) {
  const darkMode = uiStore((s) => s.darkMode)
  const setStep = uiStore((s) => s.setStep)
  const network = useStore((s) => s.network)
  const publicKey = useStore((s) => s.publicKey)
  const setCancelled = tokenStore((s) => s.setCancelled)
  const cancelled = tokenStore((s) => s.cancelled)

  return (
    <section>
      <img src={txnImg} alt="transaction" />

      <Box sx={{ margin: '40px auto' }}>
        <Typography
          variant="h4"
          style={{
            color: `${darkMode ? 'white' : 'black'}`,
          }}
        >
          Transaction Completed
        </Typography>
        {/* <Typography
          style={{
            color: `${darkMode ? 'white' : 'black'}`,
          }}
        >
          Check
        </Typography> */}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          sx={{
            backgroundColor: '#b64ef9',
            '&:hover': { backgroundColor: '#b64ef9' },
          }}
          variant="contained"
          onClick={() => {
            setStep(1)
            setCancelled(false)
            setlvlone(true)
            setlvltwo(false)
            setthree(false)
          }}
        >
          Send More
        </Button>
        {cancelled ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#b64ef9',
              '&:hover': { backgroundColor: '#b64ef9' },
              marginLeft: '10px',
            }}
            onClick={() =>
              window.open(
                `https://solscan.io/tx/${lastSignature}${
                  network === NetworkTypes1.DEVNET ? '?cluster=devnet' : ''
                }`
              )
            }
          >
            Last Successful Transaction
          </Button>
        ) : (
          <Button
            sx={{ border: '2px solid #b64ef9', color: 'white' }}
            onClick={() =>
              window.open(
                `https://solscan.io/account/${publicKey}${
                  network === NetworkTypes1.DEVNET ? '?cluster=devnet' : ''
                }`
              )
            }
          >
            {' '}
            Click Here
          </Button>
        )}
      </Box>
    </section>
  )
}
