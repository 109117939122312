import { SideNav } from '../styling'
import menu from '../../assets/menu.png'
import menuitemgold from '../../assets/MenuItemGold.png'

import './SideBarNavigation.css'

const SideBarNavigation = ({ mode, setMode, currNetwork }) => {
  return (
    <SideNav mode={mode} currNetwork={currNetwork} id="sidenav">
      <div className="dassboard">
        <div className="dasscolumn">
          {mode ? (
            <img src={menuitemgold} alt="logo" />
          ) : (
            <img src={menu} alt="logo" />
          )}
          <a href="https://nftmods.zuzprotocol.com/">
            <h2>Dashboard</h2>
          </a>
        </div>
      </div>

      <label className="switch">
        <input
          onClick={() => {
            if (mode) {
              setMode(false)
            } else {
              setMode(true)
            }
          }}
          type="checkbox"
        />
        <span className="slider round"></span>
      </label>
    </SideNav>
  )
}

export default SideBarNavigation
