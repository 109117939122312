import * as XLSX from 'xlsx'

export const converttoXLSX = (_array: any[]) => {
  let binaryWS = XLSX.utils.json_to_sheet(_array)
  var wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')
  XLSX.writeFile(wb, 'Binaire.xlsx')
}

export const convertToCSV = (arr: any[]) => {
  const array = [Object.keys(arr[0])].concat(arr)

  let result = array
    .map((it) => {
      return Object.values(it).toString()
    })
    .join('\n')

  let blob = new Blob([result], { type: 'text/csv;charset=utf-8;' })

  let url = URL.createObjectURL(blob)

  return url
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const extractfromcsv = (data: string) => {
  try {
    let _array = data.split('\n')
    let allsig: string[] = []

    for (let i = 1; i < _array.length; i++) {
      _array[i].split(',')[0] !== '' && allsig.push(_array[i].split(',')[0])
    }

    let _allsigstring = allsig.length > 0 ? allsig.join('\n') : ''

    return _allsigstring
  } catch (err) {
    console.log(err)
    return ''
  }
}
