import { ethers } from 'ethers'

import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
  TransactionInstruction,
} from '@solana/web3.js'

import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import * as borsh from 'borsh'
import { fEesCollector } from './fees'

let network = 'https://api.metaplex.solana.com/'
let program = 'A2rZzPagaCPgrJ1NHvET4zmKfD4eLM8Ceh9tFgiYMsL'
let lastSignature

export const setlastsig = (_value) => {
  lastSignature = _value
}

export const getlastsig = () => {
  return lastSignature
}

export const setDevnet = () => {
  network = 'https://api.devnet.solana.com'
  program = 'GBzma22BQb3Qfa6PiBEis6dxSAPKVDYE2Gk4qGXqihgd'
}

export const setMainnet = () => {
  network = 'https://api.metaplex.solana.com/'
  program = 'A2rZzPagaCPgrJ1NHvET4zmKfD4eLM8Ceh9tFgiYMsL'
}

// let transactions = new Transaction();

export const getConnection = async () => {
  try {
    const resp = await window.solana.connect()
    let userkey = resp.publicKey.toString()
    return userkey
  } catch (err) {
    return null
  }
}

export const getDisconnection = () => {
  window.solana.disconnect()
}

export const getTokenAccountOfTheSender = async (owner, mint_address) => {
  let connection = new Connection(network)
  let owner_pubkey = new PublicKey(owner)
  let mint_pubkey = new PublicKey(mint_address)
  try {
    let sender_token_address = await connection.getTokenAccountsByOwner(
      owner_pubkey,
      { mint: mint_pubkey }
    )
    if (sender_token_address.value.length !== 0) {
      let sender_token_account = sender_token_address.value[0].pubkey.toString()
      return sender_token_account
    } else {
      alert(
        `${owner.slice(0, 10)} does not have token account to receive the token`
      )
    }
  } catch {
    alert('Invalid Sender/Token')
  }
}

export const filterRecipientAddrress = (destination) => {
  let receivers = destination.split('\n')
  let token_amount_array = []
  let receivers_array = []
  receivers.forEach((item) => {
    let filter = item.split(',')
    token_amount_array.push(filter[1])
    receivers_array.push(filter[0])
  })

  return {
    money_array: token_amount_array,
    destination_array: receivers_array,
  }
}

export const checkForTheRecipient = async (
  _infoOject,
  _mint_address,
  _payer
) => {
  const connection = new Connection(network)
  const mint_address = new PublicKey(_mint_address)
  const payer = new PublicKey(_payer)
  let receivers = []
  let transactions = new Transaction()

  for (const item of _infoOject.destination_array) {
    let owner_account = new PublicKey(item)
    let associated_tokenAccount = await connection.getTokenAccountsByOwner(
      owner_account,
      { mint: mint_address }
    )
    if (associated_tokenAccount.value.length !== 0) {
      receivers.push(associated_tokenAccount.value[0].pubkey)
    } else {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint_address,
        owner_account
      )
      receivers.push(associated_account)

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint_address,
        associated_account,
        owner_account,
        payer
      )

      transactions.add(token_instruction)
    }
  }
  return { receivers, transactions }
}

export const talkWithProgram = async (
  _decimals,
  _receivers_token_accounts,
  _amounts,
  _sender_token_account,
  sender,
  _mint_address,
  _transactions,
  _paymentMethod
) => {
  let signature = await programInteraction(
    _decimals,
    _receivers_token_accounts,
    _amounts,
    _sender_token_account,
    sender,
    _mint_address,
    _transactions,
    _paymentMethod
  )
  return signature
}

export const programInteraction = async (
  _decimals,
  _receivers_token_accounts,
  _amounts,
  _sender_token_account,
  sender,
  _token,
  transactions,
  _paymentMethod
) => {
  for (let i = 0; i < _amounts.length; i++) {
    _amounts[i] = ethers.utils.parseUnits(_amounts[i], _decimals).toString()
  }

  //    program id
  const programId = new PublicKey(program)
  const connection = new Connection(network)
  let _sender = new PublicKey(sender)
  class MoneyTransfer {
    data = ''
    decimal = '9'
    constructor(fields) {
      if (fields) {
        this.data = fields.data
      }
    }
  }
  const MoneySchema = new Map([
    [
      MoneyTransfer,
      {
        kind: 'struct',
        fields: [
          ['data', 'String'],
          ['decimal', 'u8'],
        ],
      },
    ],
  ])
  const SEED = 'createAccount'
  const myaccount = await PublicKey.createWithSeed(_sender, SEED, programId)
  let is_exit = await connection.getAccountInfo(myaccount)
  if (is_exit === null) {
    const SIZE = borsh.serialize(MoneySchema, new MoneyTransfer()).length
    const lamports = await connection.getMinimumBalanceForRentExemption(SIZE)
    let account_creation_instuction = SystemProgram.createAccountWithSeed({
      fromPubkey: _sender,
      basePubkey: _sender,
      seed: SEED,
      newAccountPubkey: myaccount,
      lamports,
      space: SIZE,
      programId,
    })

    transactions.add(account_creation_instuction)
  }
  const programInstructions = new MoneyTransfer()
  programInstructions.data = _amounts.join(' ')
  programInstructions.decimal = _decimals

  let tokenprogram = new PublicKey(
    'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
  )
  let mint_pubkey = new PublicKey(_token)
  let sender_address = new PublicKey(_sender_token_account)
  let keys = [
    { pubkey: myaccount, isSigner: false, isWritable: true },
    { pubkey: tokenprogram, isSigner: false, isWritable: false },
    { pubkey: mint_pubkey, isSigner: false, isWritable: false },
    { pubkey: sender_address, isSigner: false, isWritable: true },
    { pubkey: _sender, isSigner: true, isWritable: true },
  ]

  _receivers_token_accounts.forEach((item) => {
    let pubkey = new PublicKey(item)
    keys.push({ pubkey: pubkey, isSigner: false, isWritable: true })
  })

  const program_instruction = new TransactionInstruction({
    keys,
    programId,
    data: Buffer.from(borsh.serialize(MoneySchema, programInstructions)),
  })

  transactions.add(program_instruction)
  transactions.feePayer = await window.solana.publicKey
  let blockhashObj = await connection.getRecentBlockhash()
  transactions.recentBlockhash = await blockhashObj.blockhash

  try {
    const newsignedTransaction = await window.solana.signTransaction(
      transactions
    )
    let sig = await connection.sendRawTransaction(
      newsignedTransaction.serialize()
    )

    await connection.confirmTransaction(sig)

    // alert(`Transaction successfull ${newsignature}`)
    return { value: true, sign: sig }
  } catch (err) {
    console.log(err)
    return { value: false, sign: '' }
  }
}

export const converter = async (_amount, _paymentMethod) => {
  let one_sol_to_usd
  let one_sol_to_usd_response
  let one_sol_to_usd_value
  let one_usd_to_sol
  let usd_to_sol
  let usd_to_lamports
  if (_paymentMethod === 'usdt') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.tether.usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else if (_paymentMethod === 'usdc') {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response['usd-coin'].usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * 1000000

    return usd_to_lamports
  } else {
    one_sol_to_usd = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
    )
    one_sol_to_usd_response = await one_sol_to_usd.json()
    one_sol_to_usd_value = one_sol_to_usd_response.solana.usd
    one_usd_to_sol = 1 / one_sol_to_usd_value
    usd_to_sol = _amount * one_usd_to_sol
    usd_to_lamports = usd_to_sol * LAMPORTS_PER_SOL

    return usd_to_lamports
  }

  // _amount: usd
  //   1 sol 253
  //
}

export const feeCalculator = async (_recivers, _paymentMethod) => {
  const feeCollector = new PublicKey(fEesCollector)
  let amount
  if (_recivers.length >= 1 && _recivers.length <= 99) {
    amount = await converter(25, _paymentMethod)
  } else if (_recivers.length >= 100 && _recivers.length <= 249) {
    amount = await converter(50, _paymentMethod)
  } else if (_recivers.length >= 250 && _recivers.length <= 499) {
    amount = await converter(75, _paymentMethod)
  } else if (_recivers.length >= 500 && _recivers.length <= 999) {
    amount = await converter(100, _paymentMethod)
  } else {
    // alert('Contact our team')
    amount = await converter(750)
  }

  if (_paymentMethod === 'usdt') {
    let ins = await payWithUSDT(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )

    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(ins)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      await connection.sendRawTransaction(newsignedTransaction.serialize())
      return true
    } catch (err) {
      console.log(ins)
      return false
    }
  }
  if (_paymentMethod === 'usdc') {
    let ins = await payWithUSDC(
      window.solana.publicKey.toString(),
      fEesCollector,
      amount
    )
    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(ins)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      await connection.sendRawTransaction(newsignedTransaction.serialize())
      await connection.confirmTransaction(newsignedTransaction)

      return true
    } catch (err) {
      console.log(ins)
      return false
    }
  } else {
    let feeInstruction = SystemProgram.transfer({
      fromPubkey: window.solana.publicKey,
      lamports: amount,
      toPubkey: feeCollector,
    })
    let connection = new Connection(network)
    let transactions = new Transaction()
    transactions.add(feeInstruction)
    transactions.feePayer = await window.solana.publicKey
    let blockhashObj = await connection.getRecentBlockhash()
    transactions.recentBlockhash = await blockhashObj.blockhash
    try {
      const newsignedTransaction = await window.solana.signTransaction(
        transactions
      )
      await connection.sendRawTransaction(newsignedTransaction.serialize())
      await connection.confirmTransaction(newsignedTransaction)

      return true
    } catch (err) {
      console.log(err)
      return false
    }
  }
}

export const payWithUSDT = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  let new_token_instruction

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      alert('You can only transfer token that you own')
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      new_token_instruction = token_instruction
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  return { new_token_instruction, token_transfer_instruction }
}

export const payWithUSDC = async (_sender, _receiver, _amount) => {
  let connection = new Connection(network)
  let sender = new PublicKey(_sender)
  let sendingTokenAddress = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
  let sendingTokenPubkey = new PublicKey(sendingTokenAddress)
  let senderTokenAccountAddress
  let new_token_instruction

  try {
    let senderTokenAccount = await connection.getTokenAccountsByOwner(sender, {
      mint: sendingTokenPubkey,
    })

    if (senderTokenAccount.value.length === 0) {
      alert('You can only transfer token that you own')
    } else {
      senderTokenAccountAddress = senderTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let receiver = new PublicKey(_receiver)
  let receiverTokenAccountAddress
  try {
    let receiverTokenAccount = await connection.getTokenAccountsByOwner(
      receiver,
      {
        mint: sendingTokenPubkey,
      }
    )

    if (receiverTokenAccount.value.length === 0) {
      let associated_account = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        receiver
      )
      receiverTokenAccountAddress = associated_account

      let token_instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        sendingTokenPubkey,
        associated_account,
        receiver,
        sender
      )
      new_token_instruction = token_instruction
    } else {
      receiverTokenAccountAddress = receiverTokenAccount.value[0].pubkey
    }
  } catch {
    alert('something went wrong')
  }

  let token_transfer_instruction = Token.createTransferCheckedInstruction(
    TOKEN_PROGRAM_ID,
    senderTokenAccountAddress,
    sendingTokenPubkey,
    receiverTokenAccountAddress,
    sender,
    [{ publicKey: sender }],
    _amount,
    6
  )

  return { new_token_instruction, token_transfer_instruction }
}
