import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import uiStore from '../../backend/uiStore'
import useStore from '../../backend/store'
import tokenStore from '../../backend/token.store'
import {
  convertToCSV,
  converttoXLSX,
  extractfromcsv,
  sleep,
} from '../Utils/download'
import downloadimg from '../../assets/cloud-computing.png'
import {
  confirmtx,
  extractsignaturedata,
  formatTransactionHistory,
} from '../../api/solana-automated'
import Lottie from 'react-lottie'
import loader from './loader.json'
import { setDevnet, setMainnet } from '../../api/solana-automated'

const Wrapper = styled.div`
  /* height: 100vh; */
  padding: 10px;
  width: 100%;
  max-width: 500px;
  background-color: #191919;
  margin-top: 50px;
  border-radius: 10px;
  position: relative;

  @media (max-width: 415px) {
    width: 300px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  width: 90%;
  margin: 20px auto;

  #successbtn:hover {
    border: none;
    background-color: green;
    color: white;
    transition: background-color 500ms ease-in;
  }

  #failedbtn:hover {
    border: none;
    background-color: red;
    color: white;
    transition: background-color 500ms ease-in;
  }
`

const VerifyCard = styled.textarea`
  border-radius: 10px;
  border: 1px solid white;
  background-color: transparent;
  padding: 10px;
  color: white;
  width: 85%;
  /* max-width: 500px; */
`

const UploadCSV = styled.div`
  color: white;
  text-decoration: underline;
  margin: 20px auto 0px auto;
  padding: 15px 0px 10px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  z-index: 999;
  cursor: pointer;
  input {
    position: absolute;
    background-color: aliceblue;
    width: 100px;
    opacity: 0;
    cursor: pointer;
  }
`

export const HeadingContainer = styled.div`
  background-color: white;
  position: absolute;
  top: -35px;
  left: 0%;
  padding: 10px 20px;
  background-color: #191919;
  color: white;
  border-radius: 5px;
`

export const CustomBtn = styled.button`
  border: 2px solid #b64ef9;
  color: white;
  padding: 15px 20px;
  background-color: transparent;
  border-radius: 5px;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;

  text-decoration: none;

  a {
    color: white;
  }

  h4 {
    margin-left: 10px;
  }

  @media (max-width: 415px) {
    font-size: 10px;
    padding: 10px 5px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-left: 10px;
  }

  &:hover {
    border: none;
    background-color: #b64ef9;
    color: white;
    transition: background-color 500ms ease-in;
  }
`

export const FetchingData = styled.div`
  color: white;
  margin: 20px 0px;
  h5 {
    font-size: 15px;
  }
`

export const Page4 = ({
  setlvlone,
  setlvltwo,
  setthree,
}: {
  setlvlone: any
  setlvltwo: any
  setthree: any
}) => {
  const transactionHashListSOL = useStore(
    (state) => state.transactionHashListSOL
  )

  const transactionHashListSPL = useStore(
    (state) => state.transactionHashListSPL
  )

  const transactionHashListNFT = useStore(
    (state) => state.transactionHashListNFT
  )

  // const [history, setHistory] = useState<any[]>()

  const setStep = uiStore((s) => s.setStep)
  const setCancelled = tokenStore((s) => s.setCancelled)
  const [url, setURL] = useState<string>('')
  const [data, setData] = useState<string>()
  const [donetx, setDonetx] = useState<number>(0)
  const [loading, setloading] = useState<boolean>(false)
  const network = useStore((state) => state.network)
  const [successtransfer, setsuccesstransfer] = useState<string[]>([])
  const [failedtransfer, setfailedtransfer] = useState<any[]>([])
  const [successurl, setSuccessURL] = useState<string>('')
  const [failedurl, setFailedURL] = useState<string>('')
  const [getdataloading, setgetdataloading] = useState(false)

  useEffect(() => {
    if (
      [
        ...transactionHashListSPL,
        ...transactionHashListSOL,
        ...transactionHashListNFT,
      ].length > 0
    ) {
      let result = convertToCSV([
        ...transactionHashListSPL,
        ...transactionHashListSOL,
        ...transactionHashListNFT,
      ])
      setURL(result)
    }
  }, [])

  const verifySignature = async () => {
    setloading(false)
    setsuccesstransfer([])
    setfailedtransfer([])

    if (data === '') {
      return
    }

    try {
      let dataarray = data?.split('\n')

      if (dataarray !== undefined) {
        setloading(true)
        for (let i = 0; i < dataarray.length; i++) {
          let result = await confirmtx(dataarray[i])

          if (result.obj !== null) {
            let _array: any[] = successtransfer
            _array.push(dataarray[i])
            setsuccesstransfer([..._array])
          } else {
            let _array: any[] = failedtransfer
            _array.push(dataarray[i])
            setfailedtransfer([..._array])
          }

          setDonetx(i + 1)
        }
        setloading(false)
      }
    } catch (err) {
      setloading(false)
      console.log(err)
    }

    setgetdataloading(true)

    if (successtransfer.length > 0) {
      let result: any

      if (
        [
          ...transactionHashListNFT,
          ...transactionHashListSPL,
          ...transactionHashListSOL,
        ].length > 0
      ) {
        result = extractsignaturedata(
          [
            ...transactionHashListNFT,
            ...transactionHashListSPL,
            ...transactionHashListSOL,
          ],
          successtransfer
        )

        let url = convertToCSV(result)
        setSuccessURL(url)
      } else {
        let signatures: any[] = []
        successtransfer.forEach((item) => {
          signatures.push({ sign: item })
        })

        result = signatures
        let url = convertToCSV(result)
        setSuccessURL(url)
      }
    }
    if (failedtransfer.length > 0) {
      let result: any

      if (
        [
          ...transactionHashListNFT,
          ...transactionHashListSPL,
          ...transactionHashListSOL,
        ].length > 0
      ) {
        result = extractsignaturedata(
          [
            ...transactionHashListNFT,
            ...transactionHashListSPL,
            ...transactionHashListSOL,
          ],
          failedtransfer
        )

        let url = convertToCSV(result)
        setFailedURL(url)
      } else {
        let signatures: any[] = []
        failedtransfer.forEach((item) => {
          signatures.push({ sign: item })
        })

        result = signatures
        let url = convertToCSV(result)
        setFailedURL(url)
      }
    }

    await sleep(3000)
    setgetdataloading(false)
  }

  useEffect(() => {
    if (network === 'Solana') {
      setMainnet()
    } else {
      setDevnet()
    }
  }, [network])

  useEffect(() => {
    let list_data = [
      ...transactionHashListNFT,
      ...transactionHashListSOL,
      ...transactionHashListSPL,
    ]

    if (list_data.length > 0) {
      let result = formatTransactionHistory(list_data)
      setData(result)
    }
  }, [transactionHashListNFT, transactionHashListSOL, transactionHashListSPL])

  return (
    <>
      <Wrapper>
        <HeadingContainer>
          <h3>Signature Verification</h3>
        </HeadingContainer>
        <ButtonContainer>
          <CustomBtn
            disabled={
              [
                ...transactionHashListSPL,
                ...transactionHashListSOL,
                ...transactionHashListNFT,
              ].length <= 0
                ? true
                : false
            }
            onClick={() => {
              converttoXLSX([
                ...transactionHashListSPL,
                ...transactionHashListSOL,
                ...transactionHashListNFT,
              ])
            }}
          >
            Transaction History{' '}
            <img src={downloadimg} alt="download" height={20} width={20} />
          </CustomBtn>

          <CustomBtn
            disabled={
              [
                ...transactionHashListSPL,
                ...transactionHashListSOL,
                ...transactionHashListNFT,
              ].length <= 0
                ? false
                : true
            }
          >
            {url !== '' ? <a href={url}> Download CSV </a> : 'Download CSV'}
            <img src={downloadimg} alt="download" height={20} width={20} />
          </CustomBtn>
        </ButtonContainer>

        <UploadCSV>
          <input
            style={{ backgroundColor: 'red' }}
            type="file"
            id="address_list_upload_1"
            accept=".csv"
            onChange={(event: any) => {
              const currFile = event.target.files[0]
              const reader = new FileReader()
              reader.onload = function (event: any) {
                const data: any = event.target.result
                setData(data.trim())
                let gotresult = extractfromcsv(data.trim())
                setData(gotresult)
              }
              reader.readAsText(currFile)
            }}
          />
          Upload CSV
        </UploadCSV>

        <VerifyCard
          rows={15}
          cols={30}
          value={data}
          onChange={(e) => {
            try {
              setData(e.target.value.trim())
            } catch (err) {
              console.log(err)
            }
          }}
        />
        <ButtonContainer>
          <CustomBtn
            onClick={() => {
              verifySignature()
            }}
          >
            {loading ? 'Verifying...' : 'Verification'}{' '}
            {data?.split('\n').length && loading && (
              <h4>
                {donetx}/{data?.split('\n').length}
              </h4>
            )}
          </CustomBtn>
          {loading && (
            <LottieWrapper animationData={loader} height={50} width={50} />
          )}
          <CustomBtn
            onClick={() => {
              setStep(1)
              setCancelled(false)
              setlvlone(true)
              setlvltwo(false)
              setthree(false)
            }}
          >
            GO BACK
          </CustomBtn>
        </ButtonContainer>

        {getdataloading ? (
          <FetchingData>
            <h5>
              Preparing Data...{' '}
              <LottieWrapper animationData={loader} height={50} width={50} />{' '}
            </h5>
          </FetchingData>
        ) : (
          <ButtonContainer>
            {successtransfer.length > 0 && loading === false && (
              <CustomBtn id="successbtn">
                {successurl === '' ? (
                  'Successfull Transfer'
                ) : (
                  <a href={`${successurl}`}>Successfull Transfer</a>
                )}
                <img src={downloadimg} alt="download" height={20} width={20} />
              </CustomBtn>
            )}

            {failedtransfer.length > 0 && loading === false && (
              <CustomBtn id="failedbtn">
                {failedurl === '' ? (
                  'Failed Transfer'
                ) : (
                  <a href={`${failedurl}`}>Failed Transfer</a>
                )}
                <img src={downloadimg} alt="download" height={20} width={20} />
              </CustomBtn>
            )}
          </ButtonContainer>
        )}
      </Wrapper>
    </>
  )
}

export const LottieWrapper = ({ animationData, height, width }: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={defaultOptions} height={height} width={width} />
}
