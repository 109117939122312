import useStore from '../../backend/store'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TextField } from '@mui/material'
import { TokenTypes1 } from '../../backend/utils'
import './scroll.css'

export const RecipientData = ({ showinput }: { showinput: boolean }) => {
  const tokenType = useStore((state) => state.tokenType)
  const recipientData = useStore((state) => state.recipientData)
  const setRecipientData = useStore((state) => state.setRecipientData)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Typography color={'white'}>Receiver Wallet</Typography>

        <Typography>
          <label
            style={{ textDecoration: 'underline' }}
            className="upload"
            htmlFor="address_list_upload_1"
          >
            Upload CSV
          </label>
          <input
            style={{ display: 'none' }}
            type="file"
            id="address_list_upload_1"
            accept=".csv"
            onChange={(event: any) => {
              const currFile = event.target.files[0]
              const reader = new FileReader()
              reader.onload = function (event: any) {
                const data: any = event.target.result
                setRecipientData(data.trim())
              }
              reader.readAsText(currFile)
            }}
          />
        </Typography>
      </Box>
      <Box>
        <TextField
          className="modifiedscroll"
          sx={{
            border: '2px solid white',
            borderRadius: '13px',
            textarea: {
              color: 'white',
            },
          }}
          multiline
          rows={10}
          fullWidth
          placeholder={
            tokenType === TokenTypes1.NFT
              ? 'Format: [ receiver wallet,mint address ]'
              : 'Format: [ receiver wallet,amount ]'
          }
          value={recipientData}
          onChange={(e) => setRecipientData(e.target.value)}
        />
      </Box>
    </>
  )
}
