interface ServerInfoInt {
  [net: string]: {
    url: string
  }
}
export const ServerInfo: ServerInfoInt = {
  Solana: {
    url: 'https://api.mainnet-beta.solana.com',
  },
  SolanaDev: {
    url: 'https://api.devnet.solana.com',
  },
}
