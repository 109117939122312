import uiStore from '../../backend/uiStore'
import useStore from '../../backend/store'
import { NetworkTypes1 } from '../../backend/utils'
import tokenStore from '../../backend/token.store'

export default function Page2({
  ischecked,
  setlvlone,
  setlvltwo,
  setthree,
}: {
  ischecked: boolean
  setlvlone: any
  setlvltwo: any
  setthree: any
}) {
  const network = useStore((s) => s.network)
  const darkMode = uiStore((s) => s.darkMode)
  const setStep = uiStore((s) => s.setStep)
  const completedTransfers = tokenStore((s) => s.completedTransfers)
  const totalTransfers = tokenStore((s) => s.totalTransfers)
  const setCancelled = tokenStore((s) => s.setCancelled)

  return (
    <section className="sectionarea">
      <div className="pagetwo">
        <div className="counter">
          <p
            style={{
              color: (darkMode && 'white') || 'black',
            }}
          >
            We are processing your transactions...
            <span>
              {completedTransfers} / {totalTransfers}
            </span>
          </p>
        </div>

        <button
          className="approvebtn3"
          disabled={completedTransfers !== totalTransfers && true}
          style={{
            backgroundColor:
              completedTransfers === totalTransfers ? '#363834' : '#000000',
          }}
          onClick={async () => {
            setlvlone(false)
            setlvltwo(false)
            setthree(true)

            if (
              network === NetworkTypes1.MAINNET ||
              network === NetworkTypes1.DEVNET
            ) {
              setStep(3)
              return
            }
            setStep(3)
          }}
        >
          Next
        </button>
        {/* {failedTransfers.length > 0 && (
          <CsvDownloader
            datas={failedTransfers as any}
            filename="failedTransfers"
          >
            <button className="approvebtn">Download</button>
          </CsvDownloader>
        )} */}

        {ischecked && (
          <button
            className="approvebtn3"
            disabled={completedTransfers === totalTransfers && true}
            style={{
              backgroundColor:
                completedTransfers === totalTransfers ? 'black' : '#363834',
            }}
            onClick={async () => {
              if (
                network === NetworkTypes1.MAINNET ||
                network === NetworkTypes1.DEVNET
              ) {
                setCancelled(true)
                // setTotalTransfers(totalTransfers)
                // setCompletedTransfers(totalTransfers)
              }
              // setStep(3)
            }}
          >
            Cancel
          </button>
        )}
      </div>
    </section>
  )
}
