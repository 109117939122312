import React, { useState } from 'react'
import * as Styles from './style'
import discord from '../../assets/discord.png'
import emailjs from 'emailjs-com'
export const Contact = ({ setcontacttoteam }) => {
  const [issending, setissending] = useState(false)
  const [sucmsg, setsucmsg] = useState('')

  const onhandlesubmit = (e) => {
    setissending(true)
    emailjs
      .sendForm(
        'service_19qdeng',
        'template_87zz5i9',
        e.target,
        'user_Go5UN3DhL1zMeloOtvEsR'
      )
      .then((res) => {
        console.log(res)
        setissending(false)
        setsucmsg('Email has been send successfully.')
      })
      .catch((err) => {
        setsucmsg('Error in sending email.s')
        console.log(err)
      })
  }

  return (
    <Styles.Wrapper>
      <Styles.FormContainer>
        <Styles.HeadingContainer>Contact Our Team </Styles.HeadingContainer>
        <Styles.FormDiv
          onSubmit={(e) => {
            e.preventDefault()
            e.target.reset()
            onhandlesubmit(e)
          }}
        >
          <Styles.FormRow>
            <label htmlFor="discordid">Discord Username</label>
            <input type="text" name="name" id="discordid" />
          </Styles.FormRow>
          <Styles.FormRow>
            <label htmlFor="emailid">Email</label>
            <input type="email" name="email" id="emailid" />
          </Styles.FormRow>
          <Styles.FormRow txt={true}>
            <label htmlFor="msgid">Message</label>
            <textarea name="msg" id="msgid" cols="47" rows="10"></textarea>
          </Styles.FormRow>
          <Styles.BtnDiv>
            <Styles.SubmitBtn
              type="submit"
              value={issending ? 'Sending...' : 'Send'}
            />
            <Styles.CloseBtn
              onClick={() => {
                setcontacttoteam(false)
              }}
            >
              Close
            </Styles.CloseBtn>
          </Styles.BtnDiv>
          {sucmsg !== '' && (
            <p style={{ backgroundColor: 'inherit', color: 'white' }}>
              {sucmsg}
            </p>
          )}
        </Styles.FormDiv>
        <a
          href="https://discord.com/invite/cyNH6KAGND"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} alt="discord" />
        </a>
      </Styles.FormContainer>
    </Styles.Wrapper>
  )
}
