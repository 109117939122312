import { Button } from '@mui/material'
import { grey } from '@mui/material/colors'

export const Button1 = ({
  title,
  onClick,
  isSelected,
}: {
  title: string
  onClick: () => void
  isSelected: boolean
}) => {
  return (
    <Button
      sx={{
        flexGrow: 1,
        margin: '10px',
        color: `${isSelected ? '#4ADCD6' : 'white'}`,
        backgroundColor: `${isSelected ? '#2A5453' : grey[700]}`,
        textTransform: 'none',
        '&:hover': { backgroundColor: '#2A5453' },
      }}
      variant="contained"
      onClick={onClick}
    >
      {title}
    </Button>
  )
}
