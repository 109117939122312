import { Connection, PublicKey } from '@solana/web3.js'
import { ServerInfo } from './serverInfo'
import { formatUnits } from './utils'

export const getSolBalance = async (_network: string, _publicKey: string) => {
  try {
    const connection = new Connection(ServerInfo[_network].url)
    const _bal1 = await connection.getBalance(new PublicKey(_publicKey))
    return formatUnits(_bal1, 9)
  } catch (err) {
    console.log(err)
    return '0'
  }
}
