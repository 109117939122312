import create from 'zustand'

interface globalState {
  contactToTeam: boolean
  setContactToTeam: (_showCont: boolean) => void

  darkMode: boolean
  setDarkMode: (_isDark: boolean) => void

  step: number
  setStep: (_step: number) => void
}

const uiStore = create<globalState>((set) => ({
  contactToTeam: false,
  setContactToTeam: (_showCont) =>
    set((state) => ({ contactToTeam: _showCont })),

  darkMode: true,
  setDarkMode: (_isDark) => set((state) => ({ darkMode: _isDark })),

  step: 1,
  setStep: (_step) => set((state) => ({ step: _step })),
}))

export default uiStore
